'use strict'

const container = document.querySelector('.container');
const header = document.querySelector('#header');
const about = document.querySelector('#section-about');
const contact = document.querySelector('#section-contact');
const mainNav = document.querySelector('#nav-main');
const palyazatNav = document.querySelector('#nav-palyazat');
const navHome = document.querySelector('#nav-main-home');
const navAbout = document.querySelector('#nav-main-about');
const navContact = document.querySelector('#nav-main-contact');
const ctaButton = document.querySelector('.headline-button');

const scrollOptions = {behavior: "smooth"};
//=================FUNCTIONS=======================

function pageScroll(target, event) {
    event.preventDefault()
    target.scrollIntoView(scrollOptions)
};

function aboutScroll(event) {

    event ? event.preventDefault() : null;
    about.style.marginTop = '5rem' 
    about.scrollIntoView(scrollOptions)
    about.style.marginTop = '14rem' 
};

//=================================================

window.addEventListener('load', () => {
    // mainNav.style.animation = 'none'
    // setTimeout(()=>{
    //     mainNav.style.animation = 'none'
    // },2000)
});

///////////////////////////////////////////////////////////////////////
/// CALL TO ACTION BUTTON

ctaButton.addEventListener('click', ()=> {
    aboutScroll()
});


//////////////////////////////////////////////////////////////////////
/// SECTION NAVIGATION

mainNav.addEventListener('click', (event) => {
    
    const {target} = event;
    
    switch (target) {
        case navHome:
            pageScroll(header, event)
            break;
        case navAbout:
            aboutScroll(event)
            break;
        case navContact:
            pageScroll(contact, event)
            break;
    
        default:
            break;
    }
})




////////////////////////////////////////////////////////////////////
/// INTERSECTION OBSERVER FOR NAV


//-------------------Főoldal---------------------------

const navObserverCallback = (entries) => {
    const navSize = mainNav.getBoundingClientRect();
    const navWidth = Math.round(navSize.width);
    if (window.innerWidth > 700){
        if (entries[0].isIntersecting) {
            mainNav.classList.add('navigation-scroll')
            mainNav.style.width = `${navWidth}px`
            mainNav.style.left = `50%`;
            mainNav.style.transform = 'translateX(-50%)'
        }else{
            mainNav.style.width = ''
            mainNav.style.left = ''
            mainNav.style.transform = ''
            mainNav.classList.remove('navigation-scroll');
        }
    }
    
}
const navObserverOptions = {threshold: 0.1}

const navObserver = new IntersectionObserver(navObserverCallback, navObserverOptions)
navObserver.observe(about)
